@import '../../settings.scss';

.search-module{
  h1, h4{
    font-family: $font-title;
    margin: 10px;
  }
  p{
    font-family: $font-text ;
  }
  .data-info{
    border-bottom: 2px solid black;
    p{
      margin-bottom: 5px;
    }
  }
  .person-data{
    margin-top: 2px;   
  }

  @media(max-width: 728px){
    .sm-window{
      display: none;
    }
    
  }
}