.nav-search-module {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 1000px;
  height: 120px;
  &:hover {
    transform: scaleX(1);
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);
    transform-origin: bottom left;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
    pointer-events: none;
    &:hover {
      transform: scaleX(1);
    }
  }

  img {
    object-fit: cover;
    width: 70%; /* Zmniejsz szerokość obrazu */
    height: 100%;
  }

  .text-container {
    position: absolute;
    top: 0;
    right: 10%; /* Przesunięcie w prawo */
    width: 30%;
    height: 100%;
    z-index: 1;
    padding: 10px;
    margin: 0;
    background-color: rgba(255, 255, 255, 0.9);
    transition: background-color 0.3s, width 0.3s;
    text-align: right;
    clip-path: polygon(100% 0, 100% 100%, 75% 100%, 0 0); /* Zmieniony punkt odniesienia */
    cursor: pointer;
    &:hover {
      background-color: #f2f2f2;
      width: 50%;
      right: 10%; /* Przesunięcie w prawo przy najechaniu */
    }
  }
}
