@import '../../settings.scss';

.footer-module{
  position:absolute;
		bottom:-100px;
		width:100%;
  font-family: $font-text;
  min-width: 100%;
  margin: 0;
  background-color: $primary-color;
  color: $white-color;
  padding-top: 20px;
  text-align: left;

  .sm-window{
    display: none;
  }
  svg{
    font-size:x-large;
    color: $white-color;
    transition: font-size 0.5s ease-in-out;

    &:hover{
      font-size: xx-large;
    }
  }
  .statute{
    cursor: pointer;
  }

  @media(max-width: 900px){
    .information{
      display: none;
    }
    .sm-window{
      display:block;
      padding-bottom: 1px;
    }
  }
}