@import '../../settings.scss';
.home-module{
  .search-filters{
    display: none;
  }
  background-color: $second-color;
  h1{
    font-family: $font-title;
    margin-top: 5px;
    }
  p{
    font-family: $font-title;
  }
}

