.advertisment-slider-module{
  margin-top: 10px;
  .slick-track{
    height: 150px;
    
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }


}