@import '../../settings.scss';

.data-module{

  .img-box{
    img{
      width: 60%;
      height: auto;
      object-fit: cover;
    }
  }
  .data-info{
    margin-top: 45px;
  }
  .card-number{
    margin-top: 30px;
  }
  .butons{
    margin-bottom: 15px;
    button{
      margin-right: 15px;
    }   
  }
}