@import '../../../settings.scss';

.new-books-module{
  padding-bottom: 10px;
  h1{
    font-family: $font-title ;
  }
}
@media(max-width: 768px){
  .new-books-module{
    .col-12{
      display: flex;
      align-items: center;
    }
    
  }
}